import React, {Component} from "react";
import {Dialog, DialogContent, Tabs, Icon} from "factor";

import styles from "./Preview.module.scss";

import mobileImage from "img/preview/mobile.png";
import desktopImage from "img/preview/desktop.png";
import tvImage from "img/preview/tv.png";
import tabletImage from "img/preview/tablet.png";

const tabs = [
	{title: "Mobile", value: "Mobile", image: mobileImage},
	{title: "Desktop", value: "Desktop", image: desktopImage},
	{title: "TV", value: "TV", image: tvImage},
	{title: "Tablet", value: "Tablet", image: tabletImage},
];

class Preview extends Component {
	constructor() {
		super();
		this.state = {
			tab: tabs[0].value,
		};
	}

	render() {
		const {
			open,
			onClickOutside,
		} = this.props;

		const {
			tab,
		} = this.state;

		return (
			<Dialog className={styles.dialog} open={!!open} onClickOutside={onClickOutside}>
				<DialogContent>
					<div className={styles.container}>
						<header className={styles.header}>
							<i className={styles.icon}>
								<Icon name="HtmlXS"/>
							</i>
							<h4 className={styles.title}>
								Violet Banner
							</h4>
							<div className={styles.size}>
								Size: 250 x 250
							</div>
							<button className={`btn-close ${styles.close}`} onClick={onClickOutside}></button>
						</header>
						<div className={styles.tabs}>
							<Tabs
								items={tabs}
								onChange={(item) => this.setState({
									tab: item.value,
								})}
								value={tab}
							/>
						</div>
						<div className={styles.image}>
							<img src={tabs.find(i => i.value === tab).image} alt="preview"/>
						</div>
					</div>
				</DialogContent>
			</Dialog>
		);
	}
};

export default Preview;