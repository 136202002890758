import React, {Component} from "react";
import {Switch, TextFieldWithIcon} from "factor";

import styles from "./Events.module.scss";

import EventsList from "./EventsList/EventsList";

import {eventsList} from "components/consts";

const eventTypes = ["Member", "Customer"];

class Events extends Component {
	state = {
		eventSwitch: true,
		search: "",
	}

	render() {
		const {
			isMultipleCustomers,
			title,
		} = this.props;

		const {
			eventSwitch,
			search,
		} = this.state;

		return (
			<div className={`block ${styles.events}`}>
				<header className={styles.header}>
					{!isMultipleCustomers ?
						<Switch
							className={styles.switch}
							name="customers-main-events"
							list={eventTypes}
							value={eventSwitch}
							onChange={(v) => this.setState({eventSwitch: v})}
						/>
						:
						<h4 className={styles.title}>
							{title} Activity
						</h4>
					}
					<TextFieldWithIcon
						placeholder="Search"
						iconName="Search"
						value={search}
						onChange={({target}) => this.setState({search: target.value})}
					/>
				</header>
				<EventsList list={eventsList}/>
			</div>
		);
	}
}

export default Events;