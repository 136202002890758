import React, { Component } from "react";
import {Table, Checkbox, Button, Icon, TextFieldWithIcon, Select} from "factor";
import {connect} from "react-redux";

import styles from "./Table.module.scss";

import {getRowClassName} from "components/helpers";

import {getData} from "store/actions";

import SelectedCampaigns from "./SelectedCampaigns/SelectedCampaigns";

import {testOptions} from "components/consts";

class TestTable extends Component {
	state = {
		search: "",
		columns: null,
	}

	getHeader = () => {
		return [
			{label: "", className: "_checkbox"},
			{label: "Customer", className: "_customer", sortingKey: "customer"},
			{label: "Date Added", className: "_dateAdded", sortingKey: "dateAdded"},
			{label: "Assignees", className: "_assignees", sortingKey: "assignees"},
			{label: "Active Campaigns", className: "_activeCampaigns", sortingKey: "activeCampaigns"},
			{label: "User Budget", className: "_userBudget", sortingKey: "userBudget"},
			{label: "User Spent", className: "_userSpent", sortingKey: "userSpent"},
		];
	}

	getBody = () => {
		const {
			data,
		} = this.props;

		const selectedLength = data.data.filter(i => i.selected).length;

		return [
			{
				key: (d) => d.total ?
					<Checkbox
						className={(selectedLength && !d.selectedAll) ? '_dirty' : ''}
						checked={d.selectedAll}
						name={`checkbox-id-${d.id}`}
					/> :
					<Checkbox
						checked={d.selected}
						name={`checkbox-id-${d.id}`}
					/>,
				className: "_checkbox"
			},
			{
				key: (d) => {
					if (d.total) {
						return (
							<span className="table__cell-total">
								Total
							</span>
						);
					} else {
						return d.customer;
					}
				},
				className: "_customer",
			},
			{ key: "dateAdded", className: "_dateAdded" },
			{ key: "assignees", className: "_assignees" },
			{ key: "activeCampaigns", className: "_activeCampaigns" },
			{ key: "userBudget", className: "_userBudget" },
			{ key: "userSpent", className: "_userSpent" },
		];
	}

	toggleSelect = (d) => {
		const {
			data,
			getData,
		} = this.props;

		const newData = JSON.parse(JSON.stringify(data));

		const tableData = newData.data;
		const tableFirstRow = newData.unsortedData;

		const selectedRowData = tableData.find(i => i.id === d.id);
		selectedRowData.selected = !selectedRowData.selected;
		selectedRowData.rowClassName = getRowClassName(selectedRowData.rowClassName, "_selected", selectedRowData.selected);

		newData.unsortedData = {
			...tableFirstRow,
			selectedAll: (tableData.filter(i => i.selected).length === tableData.length),
		};
		newData.data = tableData.map((i) => {
			return i.id === d.id ? {...i} : i;
		});

		getData(newData);
	}

	toggleSelectAll = (d) => {
		const {
			data,
			getData,
		} = this.props;

		const newData = JSON.parse(JSON.stringify(data));

		const tableData = newData.data;
		const tableFirstRow = newData.unsortedData;

		const selectedLength = tableData.filter(i => i.selected).length;

		if (newData.selectedAll || selectedLength > 0) {
			newData.unsortedData = {
				...tableFirstRow,
				selectedAll: false,
			};
			newData.data = tableData.map(i => {
				i.selected = false;
				i.rowClassName = getRowClassName(i.rowClassName, "_selected", false);
				return {...i};
			});
		} else {
			newData.unsortedData = {
				...tableFirstRow,
				selectedAll: true,
			};
			newData.data = tableData.map(i => {
				i.selected = true;
				i.rowClassName = getRowClassName(i.rowClassName, "_selected", true);
				return {...i};
			});
		}
		getData(newData);
	}

	rowClickHandler = (e, data) => {
		const { tagName } = e.target;
		const elType = e.target.getAttribute("type");

		if (tagName === "INPUT" && elType === "checkbox") {
			data.total ? this.toggleSelectAll(data) : this.toggleSelect(data);
		}
	};

	render() {
		const {
			data,
			title,
		} = this.props;

		const {
			search,
			columns,
		} = this.state;

		const selectedLength = data.data.filter(i => i.selected).length;

		return (
			<div className={`block ${styles.container}`}>
				<h4 className="title-4 mb-3">
					{title}
				</h4>
				<div className={`${styles.table}`}>
					<div className={styles.header}>
						{selectedLength > 0 && <React.Fragment>
							<SelectedCampaigns className="mb-1"/>
							<Button className={`btn-round _conflower-blue mr-5 mb-1 ${styles.actions}`}>
							<span className="btn-round__prefix">
								<Icon name="Expand"/>
							</span>
								Actions
							</Button>
						</React.Fragment>}
						<div className={styles.headerSearch}>
							<TextFieldWithIcon
								placeholder="Search"
								iconName="Search"
								value={search}
								onChange={({target}) => this.setState({search: target.value})}
							/>
						</div>
						<Select
							className={styles.headerSelect}
							placeholder="Select Columns"
							multiPlaceholder={length => `${length} Column${length > 1 ?'s' : ''} Selected`}
							value={columns}
							options={testOptions}
							onChange={(v) => this.setState({columns: v})}
							dnd
							isMulti
							allSelectable
						/>
					</div>
					<Table
						className={styles.table}
						header={this.getHeader()}
						body={this.getBody()}
						data={data.data}
						unsortedData={[data.unsortedData]}
						paging={null}
						fixedHeader={true}
						offsetTop={70}
						rowKeyExtractor={(data) => data.id}
						rowClickHandler={this.rowClickHandler}
					/>
				</div>
			</div>
		)
	}
}

export default connect(
	state => ({
		data: state.data,
	}),
	dispatch => (getData(dispatch))
)(TestTable);