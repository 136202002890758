import React, {Component} from "react";
import {Select, Button, Icon} from "factor";

import styles from "./Header.module.scss";

import {testOptions} from "components/consts";

class Header extends Component {
	state = {
		status: {label: "Approved", value: "Approved"},
	}

	render() {
		const {
			className,
			selectedCustomersLength,
		} = this.props;

		const {
			status,
		} = this.state;

		const isMultipleCustomers = selectedCustomersLength > 1;

		return (
			<div className={`${styles.header} ${className || ""}`}>
				<h2 className={`title-3 ${styles.title}`}>
					{isMultipleCustomers ?
						`${selectedCustomersLength} Customers Selected`
						:
						"Swing Left"
					}
				</h2>
				<span className={styles.subtitle}>
					{isMultipleCustomers ?
						"322 Running Campaigns"
						:
						"1A220"
					}
				</span>

				{!isMultipleCustomers ?
					<>
						<Select
							className={styles.select}
							options={[{label: 'Approved', value: 'Approved'}, ...testOptions]}
							value={status}
							onChange={(v) => this.setState({status: v})}
						/>
						<Button className={`btn-square _filled _conflower-blue mr-auto ${styles.pending}`}>
							3 Campaigns Pending
						</Button>
						<Button
							className={`btn-round _persimmon mr-3 mb-1 ${styles.headerBtn}`}
							onClick={() => this.setState({showChangesSnackbar: true})}
						>
							Reset Password
						</Button>
						<Button
							className={`btn-round _persimmon mb-1 ${styles.headerBtn}`}
							onClick={() => this.setState({showChangesSnackbar: true})}
						>
							Remove Member
						</Button>
					</>
					:
					<>
						<Button className={`btn-square _filled _conflower-blue ml-auto ${styles.pending}`}>
							44 Campaigns Pending
						</Button>
						<Button className={`btn-square _filled _conflower-blue ml-2 ${styles.pending}`}>
							14 Creatives Pending
						</Button>
						<Button className={`btn-round _filled _conflower-blue ml-2 ${styles.dashboard}`}>
							Go to Dashboard
							<span className="btn-round__prefix">
								<Icon name="Expand"/>
							</span>
						</Button>
					</>
				}
			</div>
		)
	}
}

export default Header;