import React from "react";
import {Button, ButtonCircle} from "factor";

import styles from "./SettingsAddresses.module.scss";

const SettingsAddresses = props => {
	const {
		className = "",
	} = props;

	return (
		<div className={`block ${className}`}>
			<header className={styles.header}>
				<h4 className={`title-4 ${styles.title}`}>
					Addresses
				</h4>
				<Button className="btn-square _filled _conflower-blue">
					Add Address
				</Button>
			</header>
			<div className={styles.address}>
				<p>
					411 Privet Drive, London UK 10-1299
				</p>
				<div className={`btn-round ${styles.tag}`}>
					Primary
				</div>
				<ButtonCircle outline iconName="More" className={`_size-16 ${styles.more}`}/>
			</div>
			<div className={styles.address}>
				<p>
					321 W 29 St, New York NY 12311
				</p>
				<ButtonCircle outline iconName="More" className={`_size-16 ${styles.more}`}/>
			</div>
		</div>
	);
};

export default SettingsAddresses;