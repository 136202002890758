import React, {Component} from "react";

import styles from "./Chart.module.scss";

import LineChart from "components/shared/LineChart/LineChart";

import {lineData} from "components/consts";

const list = [
	{ title: "Balance", value: "$601,232.55", status: "blue" },
	{ title: "Spent", value: "$232,875.39", status: "green" },
	{ title: "Earning", value: "$32,875.39" },
	{ title: "Data Cost", value: "$68,498.63" },
	{ title: "Actual Spent", value: "$68,498.63" },
	{ title: "Credit", value: "$68,498.63" },
	{ title: "Receivables", value: "$68,498.63" },
];

class Chart extends Component {
	state = {
		type: "Week",
	}

	render() {
		const {
			type,
		} = this.state;

		return (
			<div className={`block ${styles.container}`}>
				<ul className={styles.list}>
					{list.map(i => (
						<li key={i.title} className={`${styles.item} ${i.status ? `_${i.status}` : ""}`}>
							<div className={`text-1 ${styles.itemTitle}`}>
								{i.title}
							</div>
							<div className={`text-1 ${styles.itemValue}`}>
								{i.value}
							</div>
						</li>
					))}
				</ul>
				<div className={styles.wrapper}>
					<div className={styles.types}>
						{["Day", "Week", "Month", "Year", "Custom"].map(i => (
							<button
								key={i}
								className={`${styles.type} ${i === type ? "_active" : ""}`}
								onClick={() => this.setState({type: i})}
							>
								{i}
							</button>
						))}
					</div>
					<LineChart data={lineData}/>
				</div>
			</div>
		)
	}
}

export default Chart;