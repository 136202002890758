import React from "react";
import {connect} from "react-redux";
import {Layout} from "factor";

import styles from "./Organization.module.scss";

import Main from "components/Main/Main";
import MainContent from "components/MainContent/MainContent";
import OrganizartionSidebar from "./OrganizationSidebar/OrganizationSidebar";
import Member from "components/Member/Member";
import Role from "components/Role/Role";

import {toggleSidebar} from "store/actions";

const Organization = props => {
  const {
    organizationSwitch,
    activeSidebar,
    toggleSidebar,
  } = props;

  return (
    <Main>
      <Layout
        opened={activeSidebar}
        toggleSidebar={(opened) => toggleSidebar(typeof opened === 'boolean' ? opened : !activeSidebar)}
        sidebar={<OrganizartionSidebar/>}
      >
        <MainContent>
          {(() => {
            switch (organizationSwitch) {
              case "Members":
                return <Member/>;
              case "Roles":
                return <Role/>;
              default:
                return null;
            }
          })()}
        </MainContent>
      </Layout>
    </Main>
  )
}

export default connect(
  state => ({
    organizationSwitch: state.organizationSwitch,
    activeSidebar: state.activeSidebar,
  }),
  dispatch => (toggleSidebar(dispatch))
)(Organization);
