import React from "react";
import {Button, Icon} from "factor";

import styles from "./Summary.module.scss";

const Summary = props => (
	<div className={`block ${styles.summary}`}>
		<h4 className={`text-1 ${styles.title}`}>
			16 Running Campaigns
		</h4>
		<a href="#" className={styles.pending}>
			3 Campaigns Pending
		</a>
		<Button className={`btn-round _filled _conflower-blue ${styles.login}`}>
			Login to Dashboard
			<span className="btn-round__prefix">
				<Icon name="Expand"/>
			</span>
		</Button>
		<ul className={styles.info}>
			{[
				<React.Fragment>Approved by <a href="#" className="itemLink">PDM Admin (super)</a></React.Fragment>,
				"Created 10/25/2015",
				"Last Modified 06/08/2019",
			].map((i, k) => (
				<li className={styles.item} key={k}>
					{i}
				</li>
			))}
		</ul>
	</div>
);

export default Summary;