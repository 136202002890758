import React, {Component} from "react";
import {Icon, TextField} from "factor";

import styles from "./LandingPageCustomizations.module.scss";

import File from "components/shared/File/File";
import Radio from "components/shared/Radio/Radio";

import {ReactComponent as UploadIcon} from "img/upload.svg";
import Textarea from 'components/Textarea/Textarea';

const colors = [
	{ title: "Header Color", color: "#000000", },
	{ title: "Button Color", color: "#000000", },
	{ title: "Background Color", color: "#000000", },
	{ title: "Table Color", color: "#000000", },
	{ title: "Mouseover Color", color: "#666666", },
];

const urlRedirects = ["Home URL", "About URL", "Contact URL", "Privacy Policy URL", "Terms and Conditions URL"];

class LandingPageCustomizations extends Component {
	state = {
		bannerIndex: null,
		copyrightText: "All rights reserved @ CompanyName 2019.",
		urlRedirects: urlRedirects.map(i => ({label: i, value: ""})),
	}

	color = (color) => (
		<div className={styles.color}>
			<i style={{backgroundColor: color}}></i>
			<span>
				{color}
			</span>
		</div>
	)

	render() {
		const {
			bannerIndex,
			copyrightText,
			urlRedirects,
		} = this.state;

		return (
			<div className="block">
				<header className={styles.header}>
					<h4 className={`title-4 ${styles.title}`}>
						Landing Page Customizations
					</h4>
				</header>
				<div className="row">
					<div className="col-5">
						<h4 className="label-2 mb-2">
							Logo
						</h4>
						<div className={`${styles.logo} mb-5`}>
							<File
								withPreview
								previewClassName={styles.filePreview}
								id="Landing Page Customizations Logo"
								btnTitle={<div className={styles.uploadBtn}>
									<i>
										<UploadIcon/>
									</i>
									Upload
								</div>}
								onChange={(e) => console.log(e.target.files)}
							/>
							<i className={styles.logoInfo}>
								<Icon name="Info"/>
							</i>
						</div>

						<h4 className="label-2 mb-2">
							Banners
						</h4>
						<p className={`${styles.p} mb-3`}>
							choose an option
						</p>
						<ul className={`${styles.banners} mb-3`}>
							{[ { width: 50, height: 100 }, { width: 200, height: 100 }, ].map((i, k) => (
								<li className={styles.banner} key={k}>
									<Radio
										className="mr-3"
										name="Landing Page Customizations Banners"
										id={`Landing Page Customizations Banner ${i.width}x${i.height}`}
										checked={bannerIndex === k}
										onChange={() => this.setState({bannerIndex: k})}
									/>
									<div
										className={styles.bannerPreview}
										style={{width: i.width + "px", height: i.height + "px"}}
									></div>
								</li>
							))}
						</ul>

						<h4 className="label-2 mb-2">
							Favicon
						</h4>
						<div className={`${styles.logo}`}>
							<File
								withPreview
								id="Landing Page Customizations Favicon"
								btnTitle={<div className={styles.uploadBtn}>
									<i>
										<UploadIcon/>
									</i>
									Upload
								</div>}
								onChange={(e) => console.log(e.target.files)}
							/>
							<i className={styles.logoInfo}>
								<Icon name="Info"/>
							</i>
						</div>
					</div>
					<div className="col-3">
						<div className="row">
							{colors.map(i => (
								<div className={`col-6 ${styles.colorsItem}`} key={i.title}>
									<h4 className={`label-2 ${styles.colorsLabel}`}>
										{i.title}
									</h4>
									{this.color(i.color)}
								</div>
							))}
						</div>
						<Textarea
							className={styles.copyrightText}
							label="Copyright Text"
							value={copyrightText}
							onChange={(v) => this.setState({copyrightText: v})}
						/>
					</div>
					<div className="col-4">
						<h4 className="label-2 mb-4">
							URL Redirects
						</h4>
						{urlRedirects.map(i => (
							<div className={styles.url} key={i.label}>
								<TextField
									label={i.label}
									value={i.value}
									onChange={(v) => {
										i.value = v;
										this.setState({urlRedirects});
									}}
								/>
							</div>
						))}
					</div>
				</div>
			</div>
		);
	}
}

export default LandingPageCustomizations;