import React from "react";

import styles from "./Member.module.scss";

import MemberHeader from "./MemberHeader/MemberHeader";
import Table from "components/shared/Table/Table";

const Member = props => {

	return (
		<div className={styles.container}>
			<MemberHeader name="Ivan Guerrero"/>
			<Table title="Assigned Advertiser Accounts"/>
		</div>
	)
};

export default Member;