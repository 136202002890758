export function getRowClassName(initialClassName, newClassName, addClass) {
	if(initialClassName === undefined) {
		if(addClass) {
			return newClassName;
		} else {
			return undefined;
		}
	} else {
		if(new RegExp(newClassName).test(initialClassName)) {
			if(addClass) {
				return initialClassName
			} else {
				return initialClassName.replace(`${newClassName}`, "").trim();
			}
		} else {
			if(addClass) {
				return `${initialClassName} ${newClassName}`;
			} else {
				return initialClassName;
			}
		}
	}
}

export function arrayToRandomArray(arr) {
	const maxLength = arr.length;
	const randomArr = [...new Array(Math.round(Math.random() * (maxLength - 1) + 1))];
	return randomArr.reduce((acc, item, i) => {
		const newItem = arr[Math.round(Math.random() * (maxLength - 1))];
		if(acc.indexOf(newItem) === -1) {
			acc.push(newItem);
		}
		return acc;
	}, []);
}

export function arrayToRandomStringWithCommas(arr) {
	const maxLength = arr.length;
	const randomArr = [...new Array(Math.round(Math.random() * (maxLength - 1) + 1))];
	return randomArr.reduce((acc, item, i) => {
		const newItem = arr[Math.round(Math.random() * (maxLength - 1))];
		if(acc.indexOf(newItem) !== -1) {
			if(i + 1 === randomArr.length) {
				return acc.substring(0, acc.length - 2);
			} else {
				return acc;
			}
		} else {
			return acc + newItem + (i + 1 !== randomArr.length ? ", " : "");
		}
	}, "");
}