import React, {Component} from "react";
import {Icon} from "factor";

import styles from "./Collapse.module.scss";

class Collapse extends Component {
	state = {
		isOpened: this.props.isOpened,
	}

	render() {
		const {
			className,
			btnClassName,
			contentClassName,
			title,
			children,
		} = this.props;

		const {
			isOpened,
		} = this.state;

		return (
			<div className={`${styles.collapse} ${isOpened ? "_opened" : ""} ${className || ""}`}>
				<button className={`${styles.btn} ${btnClassName || ""}`} onClick={() => this.setState({isOpened: !isOpened})}>
					{title}
					<Icon className={styles.icon} name="Expand"/>
				</button>
				<div className={`${styles.content} ${contentClassName || ""}`}>
					{children}
				</div>
			</div>
		)
	}
}

export default Collapse;