import React, {Component} from "react";
import {NavigationBar, Tabs, ButtonCircle} from "factor";
import {withRouter, Link} from "react-router-dom";

import {ReactComponent as Logo} from "img/logo.svg";

import "./Header.scss";
import styles from "./Header.module.scss";

const tabs = [
	{title: "Organization", value: "/organization"},
	{title: "Customers", value: "/customers"},
	{title: "Settings", value: "/settings"},
];

class Header extends Component {
	constructor() {
		super();
		this.navigationBar = React.createRef();
	}

	render() {
		const popupContent = <span>popup content</span>;

		const tab = tabs.find(i => i.value === this.props.location.pathname);
		const tabValue = tab ? tab.value : null;

		return (
			<NavigationBar
				backButton={false}
				ref={this.navigationBar}
				leftLogo={<Link to="/" className="nav-bar__circle-icon" key="logo"><Logo/></Link>}
				title="Account Management"
				popupContent={popupContent}
			>
				<Tabs
					className={styles.tabs}
					items={tabs}
					onChange={(item) => this.props.history.push(item.value)}
					value={tabValue}
				/>
				<ButtonCircle outline iconName="Apps" className="_size-16 ml-auto"/>
			</NavigationBar>
		)
	}
}

export default withRouter(Header);