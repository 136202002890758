import React from "react";
import {connect} from "react-redux";

import styles from "./Role.module.scss";

import Header from "./Header/Header";
import Options from "./Options/Options";
import Table from "components/shared/Table/Table";

const Role = props => (
	<div className={styles.container}>
		<Header activeRole={props.activeRole} className="mb-4"/>
		<Options activeRole={props.activeRole} className="mb-2"/>
		{(!props.activeRole || (props.activeRole.key !== "Account Owner")) && <Table title="Assigned Members (25)"/>}
	</div>
);

export default connect(
	state => ({
		activeRole: state.activeRole,
	}),
)(Role);