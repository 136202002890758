import React, {Component} from "react";
import {TextField, Checkbox} from "factor";

import styles from "./CustomerPaymentOptions.module.scss";

import Textarea from "components/Textarea/Textarea";

class CustomerPaymentOptions extends Component {
	state = {
		checkboxes: [
			{ title: "Paypal", processing: "2-3 Processing Days", fee: "2% fee", checked: true },
			{ title: "ACH Transfer", processing: "5-10 Processing Days", fee: "no fee", checked: true },
			{ title: "Credit Card", processing: "1-2 Processing Days", fee: "3% fee", checked: true },
		],
		paypalId: "",
		minFundingAmount: "",
		customTermsAndConditions: "",
	}

	render() {
		const {
			checkboxes,
			paypalId,
			minFundingAmount,
			customTermsAndConditions,
		} = this.state;
		return (
			<div className="block">
				<header className={styles.header}>
					<h4 className={`title-4 ${styles.title}`}>
						Customer Payment Options
					</h4>
				</header>
				<ul className="mb-4">
					{checkboxes.map(i => (
						<li className={styles.option} key={i.title}>
							<Checkbox
								className={styles.optionCheckbox}
								label={i.title}
								checked={i.checked}
								name={i.title}
								onChange={() => {
									i.checked = !i.checked;
									this.setState({checkboxes});
								}}
							/>
							<div className={styles.optionProcessing}>
								{i.processing}
							</div>
							<div className={styles.optionFee}>
								{i.fee}
							</div>
						</li>
					))}
				</ul>
				<div className="row mb-4">
					<div className="col-6">
						<TextField
							label="Paypal Id"
							value={paypalId}
							onChange={(v) => this.setState({paypalId: v})}
						/>
					</div>
					<div className="col-6">
						<TextField
							label="Min Funding Amount"
							value={minFundingAmount}
							onChange={(v) => this.setState({minFundingAmount: v})}
						/>
					</div>
				</div>
				<Textarea
					className={styles.textarea}
					label="Custom Terms and Conditions"
					value={customTermsAndConditions}
					onChange={(v) => this.setState({customTermsAndConditions: v})}
				/>
			</div>
		);
	}
}

export default CustomerPaymentOptions;