import React from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";

import Header from "components/Header/Header";
import Mainpage from "components/pages/Mainpage/Mainpage";
import Organization from "components/pages/Organization/Organization";
import Customers from "components/pages/Customers/Customers";
import Settings from "components/pages/Settings/Settings";

function Routing() {
	return (
        <BrowserRouter>
            <Header/>
            <Switch>
                <Route exact path="/" component={Mainpage}/>
                <Route exact path="/organization" component={Organization}/>
                <Route exact path="/customers" component={Customers}/>
                <Route exact path="/settings" component={Settings}/>
            </Switch>
        </BrowserRouter>
	);
}

export default Routing;
