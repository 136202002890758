import React from "react";
import {Button, ButtonCircle} from "factor";

import styles from "./SettingsPaymentMethods.module.scss";

import {ReactComponent as CardIcon} from "img/card.svg";

const SettingsPaymentMethods = props => {
	const {
		className = "",
	} = props;

	return (
		<div className={`block ${className}`}>
			<header className={styles.header}>
				<h4 className={`title-4 ${styles.title}`}>
					Payment Methods
				</h4>
				<Button className="btn-square _filled _conflower-blue">
					Add Payment Methods
				</Button>
			</header>
			<div className={styles.method}>
				<i className={styles.icon}>
					<CardIcon/>
				</i>
				<div className={styles.info}>
					<h4>
						Mastercard ****3221
					</h4>
					<span>
						Tom Smith
					</span>
					<span>
						Expires 3/22
					</span>
				</div>
				<div className={`btn-round ${styles.tag}`}>
					Primary
				</div>
				<ButtonCircle outline iconName="More" className={`_size-16 ${styles.more}`}/>
			</div>
			<div className={styles.method}>
				<i className={styles.image}>
					<img
						src={require("img/paypal.png")}
						srcSet={`${require("img/paypal@2x.png")} 2x, ${require("img/paypal@3x.png")} 3x`}
						alt="paypal"
					/>
				</i>
				<div className={styles.info}>
					<h4>
						Paypal
					</h4>
					<span>
						@tsmith2100
					</span>
				</div>
				<ButtonCircle outline iconName="More" className={`_size-16 ${styles.more}`}/>
			</div>
		</div>
	);
};

export default SettingsPaymentMethods;