import {tableData, devices} from "components/consts";

let initialState = {
	data: tableData,
	activeSidebar: true,
	organizationSwitch: devices[1],
	activeRole: undefined,
	rightSidebarOpened: false,
	selectedCustomersLength: undefined,
};

function reducer(state = initialState, action) {
	switch(action.type) {
		case "GET_DATA":
			return {
				...state,
				data: action.payload
			};
		case "SIDEBAR_TOGGLE":
			return {
				...state,
				activeSidebar: action.payload,
			};
		case "ORGANIZATION_SWITCH_CHANGE":
			return {
				...state,
				organizationSwitch: action.payload,
			};
		case "ROLE_CHANGE":
			return {
				...state,
				activeRole: action.payload,
			};
		case "RIGHT_SIDEBAR_TOGGLE":
			return {
				...state,
				rightSidebarOpened: action.payload,
			};
		case "CREATIVE_PREVIEW":
			return {
				...state,
				creativePreviewOpened: action.payload,
			};
		case "CUSTOMERS_SELECT":
			return {
				...state,
				selectedCustomersLength: action.payload,
			};
		default:
			return state;
	}
}

export default reducer;
