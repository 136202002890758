import React, {Component} from "react";
import {Select, Icon, DatesPicker, Button, TextField, ButtonCircle} from "factor";

import styles from "./Invoice.module.scss";

import Sidebar from "components/SideBar/Sidebar";

import {rightSidebarHeaderOptions, timeZones} from "components/consts";

const typeSelect = [
	{ label: "Spent", value: "Spent" },
	{ label: "Platform Fee", value: "Platform Fee" },
	{ label: "Platform Setup", value: "Platform Setup" },
	{ label: "Other", value: "Other" },
];

class Invoice extends Component {
	state = {
		headerSelect: rightSidebarHeaderOptions[0],
		timezone: timeZones[0],
		dateRange: {
			start: new Date(1541192400000),
			end: new Date(1541765208969),
		},
		expenseType: typeSelect[0],
		amount: "",
		showAddExpensesForm: false,
		expenses: [
			{
				description: "Monthly Spent",
				type: "Spent",
				amount: "$36,000",
			},
		],
		description: "",
	}

	header = () => {
		const {
			close,
		} = this.props;

		const {
			headerSelect,
		} = this.state;

		return (
			<div className={styles.header}>
				<button className={`btn-back ${styles.back}`} onClick={close}>
					<Icon name="Left"/>
				</button>
				<h4 className={`title-4 ${styles.title}`}>
					Invoice
				</h4>
				<Select
					className={styles.headerSelect}
					options={rightSidebarHeaderOptions}
					value={headerSelect}
					onChange={(v) => this.setState({headerSelect: v})}
				/>
			</div>
		)
	}

	render() {
		const {
			className,
		} = this.props;

		const {
			timezone,
			dateRange,
			expenseType,
			amount,
			showAddExpensesForm,
			expenses,
			description,
		} = this.state;

		return (
			<Sidebar
				className="_right-sidebar"
				notFixed
				header={this.header()}
			>
				<div className={`${styles.container} ${className || ""}`}>
					<div className={`${styles.datespicker} mb-4`}>
						<div className={styles.datespickerTimezone}>
							<Select
								className={styles.select}
								options={timeZones}
								value={timezone}
								onChange={(v) => this.setState({timezone: v})}
							/>
						</div>
						<div className="datepicker _sm">
							<DatesPicker
								updateDateRange={(start, end) => this.setState({
									dateRange: {
										start: new Date(start),
										end: new Date(end),
									}
								})}
								dateFormat="DD/MM/YYYY"
								dateRange={dateRange}
								datePickerProps={{
									numberOfCalendars: 2,
									maximumDate: new Date(),
									animationAxis: "Y",
								}}
							/>
						</div>
					</div>
					{!showAddExpensesForm ?
						<table className={styles.table}>
							<thead>
								<tr>
									{["Description", "Type", "Amount"].map(i => (
										<th key={i}>
											{i}
										</th>
									))}
								</tr>
							</thead>
							<tbody>
								{expenses.length > 0 &&
									expenses.map((i, k) => (
										<tr key={k}>
											<td>
												<div className="d-flex align-items-center">
													<ButtonCircle
														outline
														iconName="Edit"
														className={`_size-14 ${styles.tableCircleBtn}`}
													/>
													{i.description}
												</div>
											</td>
											<td>
												{i.type}
											</td>
											<td>
												<div className="d-flex align-items-center justify-content-end">
													{i.amount}
													<ButtonCircle
														outline
														iconName="Delete"
														className={`_size-14 ${styles.tableCircleBtn}`}
														onClick={() => {
															expenses.splice(k, 1);
															this.setState({expenses});
														}}
													/>
												</div>
											</td>
										</tr>
									))
								}
								<tr>
									<td>
										<button
											className={styles.tableBtn}
											onClick={() => this.setState({showAddExpensesForm: true})}
										>
											Add Expenses
										</button>
									</td>
									<td></td>
									<td></td>
								</tr>
								<tr>
									<td>
										<button className={styles.tableBtn}>
											Add Discount
										</button>
									</td>
									<td></td>
									<td></td>
								</tr>
							</tbody>
						</table>
						:
						<div className={styles.form}>
							<ul className={styles.formList}>
								<li className={styles.formItem}>
									<h4 className={styles.label}>
										Description
									</h4>
									<textarea
										className={`text ${styles.textarea} _form`}
										value={description}
										onChange={({target}) => this.setState({description: target.value})}
									/>
								</li>
								<li className={styles.formItem}>
									<Select
										label="Type"
										showControlLabel
										value={expenseType}
										options={typeSelect}
										onChange={(v) => this.setState({expenseType: v})}
									/>
								</li>
								<li className={styles.formItem}>
									<TextField
										label="Amount"
										value={amount}
										onChange={(v) => this.setState({amount: v})}
									/>
								</li>
							</ul>
							<div className="d-flex justify-content-end mt-2">
								<Button
									className="btn-square _conflower-blue mr-2"
									onClick={() => this.setState({
										showAddExpensesForm: false,
										description: "",
										type: typeSelect[0],
										amount: "",
									})}
								>
									Cancel
								</Button>
								<Button
									className="btn-square _filled _conflower-blue"
									onClick={() => {
										expenses.push({
											description: description,
											type: expenseType.label,
											amount,
										});

										this.setState({
											showAddExpensesForm: false,
											description: "",
											type: typeSelect[0],
											amount: "",
											expenses,
										});
									}}
								>
									Add
								</Button>
							</div>
						</div>
					}

					<h4 className={styles.label}>
						note
					</h4>
					<textarea className={`text ${styles.textarea} _note mb-3`}></textarea>

					<div className={styles.finances}>
						<div className={`${styles.financesRow} _total`}>
							<div className={styles.financesCol}>
								Total:
							</div>
							<div className={styles.financesCol}>
								$0.00
							</div>
						</div>
						<div className={styles.financesRow}>
							<div className={styles.financesCol}>
								Available Funds:
							</div>
							<div className={styles.financesCol}>
								$601,232.55
							</div>
						</div>
						<div className={styles.financesRow}>
							<div className={styles.financesCol}>
								Final Balance:
							</div>
							<div className={styles.financesCol}>
								$601,232.55
							</div>
						</div>
					</div>
					<div className="d-flex justify-content-end mt-5">
						<Button className="btn-square _filled _conflower-blue">
							Send Invoice
						</Button>
					</div>
				</div>
			</Sidebar>
		)
	}
}



export default Invoice;