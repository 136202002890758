import React, {Component} from "react";
import {Select, Button} from "factor";

import styles from "./Finances.module.scss";

import Sidebar from "components/SideBar/Sidebar";
import LineChart from "components/shared/LineChart/LineChart";
import FinancesList from "./FinancesList/FinancesList";
import Invoice from "./Invoice/Invoice";

import {rightSidebarHeaderOptions, lineData} from "components/consts";

const list = [
	{ name: "Re-Elect Kristie Weaver", id: 10038 },
	{ name: "Warren for District 1", id: 12992 },
	{ name: "Gilead \"Across America\"", id: 10233 },
	{ name: "Shine On", id: 40933 },
	{ name: "Re-Elect Kristie Weaver", id: 10039 },
	{ name: "Warren for District 1", id: 12993 },
	{ name: "Gilead \"Across America\"", id: 10234 },
	{ name: "Shine On", id: 40934 },
];

class Finances extends Component {
	state = {
		headerSelect: rightSidebarHeaderOptions[0],
		showInvoice: false,
	}

	header = () => {
		const {
			close,
		} = this.props;

		const {
			headerSelect,
		} = this.state;

		return (
			<div className={styles.header}>
				<div className={styles.headerRow}>
					<button
						className={`btn-close ${styles.close}`}
						onClick={close}
					></button>
					<h4 className={`title-4 ${styles.title}`}>
						Finances
					</h4>
					<Select
						className={styles.headerSelect}
						options={rightSidebarHeaderOptions}
						value={headerSelect}
						onChange={(v) => this.setState({headerSelect: v})}
					/>
				</div>
			</div>
		)
	}

	render() {
		const {
			showInvoice,
		} = this.state;

		return showInvoice ?
			<Invoice close={() => this.setState({showInvoice: false})}/>
			:
			<Sidebar
				className="_right-sidebar"
				contentClassName={styles.content}
				notFixed
				header={this.header()}
			>
				<div className={styles.chartBlock}>
					<p className={styles.chartWarning}>
						Swing Left will run out of funds in 16 hours at the current rate of spending.
					</p>
					<div className={styles.chart}>
						<LineChart
							className="_finances"
							data={lineData}
							options={{
								margin: {top: 10, right: 16, bottom: 36, left: 32},
							}}
						/>
					</div>
					<div className={styles.funds}>
						<button className={`btn-close ${styles.fundsAdd}`}></button>
						<h4 className={styles.fundsTitle}>
							Funds $601,232.55
						</h4>
						<div className={styles.credit}>
							<div className={styles.creditAvailable}>
								available credit <span className="ml-2">$55,000.00</span>
							</div>
							<button className={styles.creditPreapprove}>
								Pre-approve credit
							</button>
						</div>
					</div>
				</div>
				<FinancesList className={styles.list} list={list}/>
				<div className={styles.buttons}>
					<Button
						className="btn-round _filled _conflower-blue"
						onClick={() => this.setState({showInvoice: !showInvoice})}
					>
						Invoice
					</Button>
				</div>
			</Sidebar>
	}
}



export default Finances;