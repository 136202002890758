import React from "react";
import {Checkbox} from "factor";

import styles from "./List.module.scss";

const List = props => {
	const {
		className,
		header,
		list,
		onChange = () => {},
		active,
		onCheckboxChange,
	} = props;

	return (
		<div className={styles.container}>
			<ul className={`${styles.list} ${className || ""}`}>
				{header &&
					<li className={`${styles.item} _header`}>
						{header.map(i => (
							<div className={styles.th} key={i}>
								{i}
							</div>
						))}
					</li>
				}
				{list.map((i, k) => (
					<li
						className={`${styles.item} ${(active && active.key === i.key) ? "_active" : ""} ${i.checked ? "_checked" : ""}`}
						key={i.key}
						onClick={() => onChange(i)}
					>
						<div className={styles.key}>
							{i.hasOwnProperty("checked") &&
								<Checkbox
									className="mr-3"
									checked={i.checked}
									onChange={() => onCheckboxChange(k)}
								/>
							}
							{i.dot &&
								<span className={`${styles.dot} _${i.dot}`}></span>
							}
							{i.key}
						</div>
						<div className={styles.value}>
							{i.value}
						</div>
					</li>
				))}
			</ul>
		</div>
	)
}

export default List;