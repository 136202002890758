import React, {Component} from 'react';
import {connect} from 'react-redux';

import {getRowClassName} from "components/helpers";

import {getData} from "store/actions";

import styles from "./SelectedCampaigns.module.scss";

class SelectedCampaigns extends Component {
	handleClear = () => {
		const {
			data,
			getData,
		} = this.props;

		const newData = JSON.parse(JSON.stringify(data));

		const tableData = newData.data;
		const tableFirstRow = newData.unsortedData;

		newData.unsortedData = {
			...tableFirstRow,
			selectedAll: false,
		};
		newData.data = tableData.map(i => {
			i.selected = false;
			i.rowClassName = getRowClassName(i.rowClassName, "_selected", false);
			return {...i};
		});

		getData(newData);
	};

	render() {
		const {
			className,
			data,
		} = this.props;

		const selectedLength = data.data.filter(i => i.selected).length;

		return (
			<div className={`${styles.selected} ${className || ""}`}>
				<button className={`btn-close ${styles.remove}`} onClick={this.handleClear}/>
				{`${selectedLength} Audience${selectedLength > 1 ? "s" : ""} selected`}
			</div>
		);
	}
}

export default connect(
	state => ({
		data: state.data,
	}),
	dispatch => (getData(dispatch))
)(SelectedCampaigns);