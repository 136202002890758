import React, {Component} from "react";
import {Select, Button, Checkbox, Icon} from "factor";

import styles from "./Campaigns.module.scss";

import Sidebar from "components/SideBar/Sidebar";
import Campaign from "./Campaign/Campaign";

import {testOptions} from "components/consts";

const headerSelectOptions = [{label: "SwingLeft", value: "SwingLeft"}, ...testOptions];

const campaigns = [
	{ name: "Re-Elect Kristie Weaver", created: "08/23/2018", param: "10038" },
	{ name: "Warren for D1", created: "08/23/2018", param: "10038" },
	{ name: "Gilead \"Same Love\"", created: "08/23/2018", param: "10038" },
	{ name: "Buttigieg: Another Way", created: "08/23/2018", param: "10038" },
];

class Campaigns extends Component {
	state = {
		headerSelect: headerSelectOptions[0],
		campaigns: campaigns.map(i => ({checked: false, ...i})),
		campaign: undefined,
	}

	header = () => {
		const {
			close,
		} = this.props;

		const {
			headerSelect,
		} = this.state;

		return (
			<div className={styles.header}>
				<div className={styles.headerRow}>
					<button
						className={`btn-close ${styles.close}`}
						onClick={close}
					></button>
					<h4 className={`title-4 ${styles.title}`}>
						Approve Campaigns
					</h4>
					<Select
						className={styles.headerSelect}
						options={headerSelectOptions}
						value={headerSelect}
						onChange={(v) => this.setState({headerSelect: v})}
					/>
				</div>
				<div className={`${styles.headerRow} ${styles.pending}`}>
					<div className={styles.pendingTitle}>
						14 Campaigns Pending
					</div>
					<Button className={`btn-round _filled _conflower-blue ${styles.pendingApprove}`}>
						Approve All
					</Button>
				</div>
			</div>
		)
	}

	render() {
		const{
			campaigns,
			campaign,
		} = this.state;

		const selectedLength = campaigns.filter(i => i.checked).length;

		return (
			<Sidebar
				className="_right-sidebar"
				notFixed
				header={this.header()}
			>
				{campaign ?
					<Campaign {...campaign} close={() => this.setState({campaign: undefined})}/>
					:
					<React.Fragment>
						{!!selectedLength &&
						<div className={styles.controls}>
							<button
								className={`btn-close ${styles.remove}`}
								onClick={() => {
									campaigns.forEach(i => i.checked = false);
									this.setState({campaigns});
								}}
							></button>
							<div className={styles.selected}>
								{selectedLength} Campaign{selectedLength > 1 ? "s" : ""} Selected
							</div>
							<Button className={`btn-round _conflower-blue mr-2 ml-auto ${styles.controlsBtn}`}>
								Decline
							</Button>
							<Button className={`btn-round _filled _conflower-blue ${styles.controlsBtn}`}>
								Approve
							</Button>
						</div>
						}
						<ul className={styles.list}>
							<li className={styles.listHeader}>
								<div className={`${styles.listTh} _name`}>
									Name
								</div>
								<div className={styles.listTh}>
									Created
									<Icon name="Expand"/>
								</div>
							</li>
							{campaigns.map(i => (
								<li className={styles.item} key={i.name}>
									<Checkbox
										className={styles.checkbox}
										label=""
										checked={i.checked}
										onChange={() => {
											i.checked = !i.checked;
											this.setState({campaigns});
										}}
									/>
									<div className={styles.wrapper}>
										<h4 className={styles.name}>
											{i.name}
										</h4>
										<span className={styles.param}>
											{i.param}
										</span>
									</div>
									<span className={styles.created}>
										{i.created}
									</span>
									<button className={styles.more} onClick={() => this.setState({campaign: i})}>
										more
									</button>
								</li>
							))}
						</ul>
					</React.Fragment>
				}
			</Sidebar>
		)
	}
}



export default Campaigns;