import React, {Component} from "react";
import {connect} from "react-redux";

import styles from "./RightSidebar.module.scss";

import Creatives from "./Creatives/Creatives";
import Campaigns from "./Campaigns/Campaigns";
import Finances from "./Finances/Finances";

import {ReactComponent as ArrowLeftIcon} from "img/arrow-left.svg";
import {ReactComponent as ApprovedCampaignsIcon} from "img/approved-campaigns.svg";
import {ReactComponent as ApprovedCreativesIcon} from "img/approved-creatives.svg";
import {ReactComponent as FinancesIcon} from "img/finances.svg";

import {toggleRightSidebar} from "store/actions";

class RightSidebar extends Component {
	state = {
		opened: undefined,
	}

	componentDidMount() {
		this.props.toggleRightSidebar(true);
	}

	componentWillUnmount() {
		this.props.toggleRightSidebar(false);
	}

	buttons = [
		{ title: "campaigns", icon: <ApprovedCampaignsIcon/> },
		{ title: "creatives", icon: <ApprovedCreativesIcon/> },
		{ title: "finances", icon: <FinancesIcon/> }
	]

	render() {
		const {
			toggleRightSidebar,
			rightSidebarOpened,
		} = this.props;

		const {
			opened,
		} = this.state;

		return (
			<section className={`${styles.section} ${rightSidebarOpened ? "" : "_hidden"}`}>
				{(() => {
					switch(opened) {
						case this.buttons[0].title:
							return <Campaigns close={() => this.setState({opened: false})}/>;
						case this.buttons[1].title:
							return <Creatives close={() => this.setState({opened: false})}/>;
						case this.buttons[2].title:
							return <Finances close={() => this.setState({opened: false})}/>;
						default:
							return null;
					}
				})()}
				<ul className={styles.buttons}>
					{this.buttons.map(i => {
						let _opened = opened === i.title;
						return (
							<li className={styles.buttonsItem} key={i.title}>
								<button
									className={`${styles.btn} _${i.title} ${_opened ? "_active" : ""}`}
									onClick={() => this.setState({opened: _opened ? false : i.title})}
								>
									{i.icon}
								</button>
								<div className={styles.buttonsNotifications}>
									14
								</div>
							</li>
						);
					})}
					<button
						className={styles.arrow}
						onClick={() => {
							this.setState({opened: undefined});
							toggleRightSidebar(!rightSidebarOpened);
						}}
					>
						<ArrowLeftIcon/>
					</button>
				</ul>
			</section>
		)
	}
}

export default connect(
	state => ({
		rightSidebarOpened: state.rightSidebarOpened,
		sidebarTransitionEnd: state.sidebarTransitionEnd,
	}),
	dispatch => toggleRightSidebar(dispatch),
)(RightSidebar);