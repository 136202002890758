import React, {Component} from "react";
import {
	ButtonCircle,
	Icon,
	Dialog,
	DialogContent,
	Button,
	TextField,
} from "factor";

import styles from "./Menu.module.scss";

class Menu extends Component {
	constructor() {
		super();
		this.state = {
			isOpen: false,
			showRenameModal: false,
			groupName: "",
		}
	}

	componentDidMount() {
		window.addEventListener("click", this.windowClickHandler);
	}

	componentWillUnmount () {
		window.removeEventListener("click", this.windowClickHandler);
	}

	windowClickHandler = ({target}) => {
		if(target.closest(".js-menu") === this.menu) return;
		this.setState({
			isOpen: false,
		})
	}

	toggleMenu = () => {
		this.setState({
			isOpen: !this.state.isOpen,
		})
	}

	stopPropagation = e => e.stopPropagation();

	render() {
		const {
			className,
			list,
			isTile,
		} = this.props;

		const {
			isOpen,
			showRenameModal,
			groupName,
		} = this.state;

		return (<React.Fragment>
			<div
				className={`${styles.container} ${isOpen ? "_opened" : ""} ${isTile ? "_tile" : ""} ${className || ""} js-menu`}
				ref={el => this.menu = el}
			>
				<ButtonCircle
					outline={true}
					iconName={isOpen ? isTile ? "Close" : "DropUp" : "More"}
					className={`${styles.btn} _size-16`}
					onClick={this.toggleMenu}
				/>
				<div className={styles.list} onClick={this.stopPropagation}>
					<h4 className={styles.title}>
						Actions
					</h4>
					{list.map((item, i) => (
                        <button
                            key={i}
                            className={styles.itemBtn}
                            onClick={e => {
                                this.toggleMenu();
                                if(item.title === "Rename") {
                                	this.setState({showRenameModal: true});
								} else {
									item.onClick && item.onClick(e);
								}
                            }}
                        >
							<i className={styles.icon}>
								<Icon name={item.icon}/>
							</i>
							{item.title}
						</button>
					))}
				</div>
			</div>

			<Dialog className="" open={showRenameModal} onClickOutside={() => this.setState({showRenameModal: false})}>
				<DialogContent>
					<div className={`${styles.modalTitle} d-flex justify-content-between mb-1`}>
						Rename
						<button className="btn-close" onClick={() => this.setState({showRenameModal: false})}></button>
					</div>
					<div className="">
						<TextField
							name="new-group-name"
							label="New Group Name"
							onChange={( v ) => this.setState({
								groupName: v,
							})}
							value={groupName}
						/>
					</div>
					<div className="d-flex justify-content-end mt-3">
						<Button className="btn-square _conflower-blue mr-2" onClick={() => this.setState({showRenameModal: false})}>
							Cancel
						</Button>
						<Button className="btn-square _conflower-blue _filled" onClick={() => this.setState({showRenameModal: false})}>
							Apply
						</Button>
					</div>
				</DialogContent>
			</Dialog>
		</React.Fragment>)
	}
}

export default Menu;