import React, {Component} from "react";
import {TextFieldWithIcon, Button, Select} from "factor";
import {connect} from "react-redux";

import {changeSelectedCustomersLength} from "store/actions";

import styles from "./CustomersSidebar.module.scss";

import Sidebar from "components/SideBar/Sidebar";
import List from "components/shared/List/List";
import Invite from "./Invite/Invite";

const list = [
	{ key: "Swing Left", value: "$37417", checked: true, dot: "blue" },
	{ key: "GoPro", value: "$97704", checked: false },
	{ key: "Indivisible", value: "$67323", checked: false },
	{ key: "NRA", value: "$52612", checked: false, dot: "blue" },
	{ key: "INDNC", value: "$39192", checked: false },
	{ key: "Jim Munoz", value: "$5866", checked: false },
];

const sidebarListTypes = [
	{ label: "Balance", value: "Balance"},
	{ label: "Credit", value: "Credit"},
	{ label: "Spent", value: "Spent"},
	{ label: "Earning", value: "Earning"},
	{ label: "Margin (%)", value: "Margin (%)"},
];

class CustomersSidebar extends Component {
	state = {
		search: "",
		list: JSON.parse(JSON.stringify(list)),
		showInviteForm: false,
		sidebarListType: sidebarListTypes[0],
	}

	componentDidMount() {
		this.props.changeSelectedCustomersLength(this.state.list.filter(i => i.checked).length);
	}

	headerListSelect = () => (
		<Select
			className={styles.listSelect}
			value={this.state.sidebarListType}
			options={sidebarListTypes}
			onChange={(v) => this.setState({sidebarListType: v})}
		/>
	)

	header = () => {
		const {
			changeSelectedCustomersLength
		} = this.props;

		const {
			search,
			list,
		} = this.state;

		const allChecked = list.length === list.filter(i => i.checked).length;

		return (
			<React.Fragment>
				<div className={styles.title}>
					<h4 className={`title-4`}>
						Customers (15)
					</h4>
					<button
						className={styles.selectAll}
						onClick={() => {
							if(list.length > list.filter(i => i.checked).length) {
								list.forEach(i => i.checked = true);
							} else {
								list.forEach(i => i.checked = false);
							}
							this.setState({list});
							changeSelectedCustomersLength(list.filter(i => i.checked).length);
						}}
					>
						{`${allChecked ? "Des" : "S"}elect All`}
					</button>
				</div>
				<div className={styles.search}>
					<TextFieldWithIcon
						iconName="Search"
						value={search}
						placeholder="Search"
						onChange={(e) => this.setState({search: e.target.value})}
					/>
				</div>
			</React.Fragment>
		)
	}

	render() {
		const {
			changeSelectedCustomersLength,
		} = this.props;

		const {
			list,
			showInviteForm,
		} = this.state;

		return (
			<Sidebar
				header={this.header()}
				lastChildren={
					<Invite
						className={showInviteForm ? "" : "_hidden"}
						close={() => this.setState({showInviteForm: false})}
					/>
				}
				containerHidden={showInviteForm}
			>
				<List
					className={styles.list}
					controls
					header={["Name", this.headerListSelect()]}
					list={list}
					onCheckboxChange={(i, isAll) => {
						if(isAll) {
							if(list.length > list.filter(i => i.checked).length) {
								list.forEach(i => i.checked = true);
							} else {
								list.forEach(i => i.checked = false);
							}
						} else {
							list[i].checked = !list[i].checked;
						}
						this.setState({list});
						changeSelectedCustomersLength(list.filter(i => i.checked).length);
					}}
				/>
				<div className={styles.buttons}>
					<Button
						className="btn-round _filled _conflower-blue"
						onClick={() => this.setState({showInviteForm: true})}
					>
						Invite Customers
					</Button>
				</div>
			</Sidebar>
		)
	}
}

export default connect(
	state => ({
		selectedCustomersLength: state.selectedCustomersLength,
	}),
	dispatch => changeSelectedCustomersLength(dispatch),
)(CustomersSidebar);