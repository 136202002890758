import React, {Component} from "react";
import {Select, DatesPicker, Icon, TextField, Button} from "factor";

import styles from "./Campaign.module.scss";

import Collapse from "components/shared/Collapse/Collapse";

import {testOptions, timeZones} from "components/consts";

const countries = [{label: "United States", value: "United States"}, ...testOptions];
const campaignGroups = [{label: "October Campaign", value: "October Campaign"}, ...testOptions];
const deviceTypes = [{label: "Desktop", value: "Desktop"}, ...testOptions];

class Campaign extends Component {
	state = {
		timezone: timeZones[0],
		dateRange: {
			start: new Date(1541192400000),
			end: new Date(1541765208969),
		},
		campaignName: this.props.name,
		country: countries[0],
		campaignGroup: campaignGroups[0],
		deviceType: deviceTypes[0],
		adDomain: "",
	}

	render() {
		const{
			name,
			created,
			param,
			close,
		} = this.props;

		const{
			timezone,
			dateRange,
			campaignName,
			country,
			campaignGroup,
			deviceType,
			adDomain,
		} = this.state;

		return (
			<div className={styles.campaign}>
				<header className={styles.header}>
					<button className={`btn-back ${styles.back}`} onClick={close}>
						<Icon name="Left"/>
					</button>
					<div className={styles.headerWrapper}>
						<div className={styles.title}>
							{name}
						</div>
						<div className={styles.id}>
							ID: {param}
						</div>
					</div>
					<div className={styles.created}>
						Created {created}
					</div>
				</header>
				<div className={styles.collapses}>
					<Collapse
						className={styles.collapse}
						contentClassName={styles.collapseContent}
						btnClassName={styles.collapseBtn}
						title="Basic Info"
						isOpened={true}
					>
						<div className={`${styles.datespicker}`}>
							<div className={styles.datespickerTimezone}>
								<Select
									className={styles.select}
									options={timeZones}
									value={timezone}
									onChange={(v) => this.setState({timezone: v})}
								/>
							</div>
							<div className="datepicker _sm">
								<DatesPicker
									updateDateRange={(start, end) => this.setState({
										dateRange: {
											start: new Date(start),
											end: new Date(end),
										}
									})}
									dateFormat="DD/MM/YYYY"
									dateRange={dateRange}
									datePickerProps={{
										numberOfCalendars: 2,
										maximumDate: new Date(),
										animationAxis: "Y",
									}}
								/>
							</div>
						</div>
						<div className={`${styles.row}`}>
							<TextField
								label="* Campaign Name"
								value={campaignName}
								onChange={(v) => this.setState({campaignName: v})}
							/>
						</div>
						<div className={`row ${styles.row}`}>
							<div className="col-6">
								<Select
									className={styles.select}
									options={countries}
									value={country}
									onChange={(v) => this.setState({country: v})}
								/>
							</div>
							<div className="col-6">
								<Select
									className={styles.select}
									options={campaignGroups}
									value={campaignGroup}
									onChange={(v) => this.setState({campaignGroup: v})}
								/>
							</div>
						</div>
						<div className={`row ${styles.row}`}>
							<div className="col-6">
								<Select
									className={styles.select}
									options={deviceTypes}
									value={deviceType}
									onChange={(v) => this.setState({deviceType: v})}
								/>
							</div>
							<div className="col-6">
								<TextField
									label="* Ad Domain"
									value={adDomain}
									onChange={(v) => this.setState({adDomain: v})}
								/>
							</div>
						</div>
					</Collapse>

					{[<h4>Creatives <span>(3 pending)</span></h4>, "Goal AI", "Budget"].map((i, k) => (
						<Collapse
							key={k}
							className={styles.collapse}
							contentClassName={styles.collapseContent}
							btnClassName={styles.collapseBtn}
							title={i}
						>
							{i}
						</Collapse>
					))}
				</div>
				<div className={styles.buttons}>
					<Button className="btn-square _conflower-blue">
						Decline
					</Button>
					<Button className="btn-square _filled _conflower-blue">
						Approve
					</Button>
				</div>
			</div>
		)
	}
}



export default Campaign;