import React, {Component} from "react";
import {connect} from "react-redux";

import CreativesListItem from "components/CreativesList/CreativesListItem/CreativesListItem";

import styles from "./CreativesList.module.scss";

import {creativesList} from "components/consts";

class CreativesList extends Component {
	constructor(props) {
		super();
		this.mode = props.mode;
		this.state = {
			list: JSON.parse(JSON.stringify(props.list ? props.list : creativesList)),
			view: "list",
		}
	}

	componentDidUpdate() {
		if(this.mode !== this.props.mode) {
			this.mode = this.props.mode;
			this.setState({list: JSON.parse(JSON.stringify(this.props.list ? this.props.list : creativesList))});
		}
	}

	render() {
		const {
			column,
			isTotal,
		} = this.props;

		let {
			view,
			list,
		} = this.state;

		view = this.props.view || view;

		return (<React.Fragment>
			<ul className={`${styles.list} ${view === "list" ? "" : "_tile"} ${column ? "_column" : ""}`}>
				{list.map((i, k) => (
					<li className={styles.item} key={i.id}>
						<CreativesListItem
							{...i}
							view={view}
							isTotal={isTotal}
							onCheckboxChange={() => {
								list[k].checked = !list[k].checked;
								this.setState({list});
							}}
						/>
					</li>
				))}
			</ul>
		</React.Fragment>);
	}
}

export default connect(
	state => ({
		mode: state.mode,
	})
)(CreativesList);