import React, {Component} from "react";
import {Button, TextField, Select, Checkbox} from "factor";

import styles from "./SettingsBasicInfo.module.scss";

import File from "components/shared/File/File";

import {ReactComponent as UploadIcon} from "img/upload.svg";

import {testOptions, timeZones} from "components/consts";

const languages = [ {label: "English", value: "English" }, ...testOptions ];
const advertisementBudgets = [ {label: "1-50000", value: "1-50000" }, ...testOptions ];

const segmentSettings = [
	{ label: "Make Segments Live", checked: false },
	{ label: "Public Segments", checked: false },
	{ label: "View Public Segments", checked: true },
];

class SettingsBasicInfo extends Component {
	state = {
		contactPerson: "",
		organizationName: "",
		contactNumber: "",
		contactEmail: "",
		appUrl: "",
		timezone: timeZones[0],
		language: languages[0],
		businessType: "",
		advertisementBudget: advertisementBudgets[0],
		segmentSettings: JSON.parse(JSON.stringify(segmentSettings)),
	}

	render() {
		const {
			contactPerson,
			organizationName,
			contactNumber,
			contactEmail,
			appUrl,
			timezone,
			language,
			businessType,
			advertisementBudget,
			segmentSettings,
		} = this.state;
		return (
			<div className="block">
				<header className={styles.header}>
					<h4 className={`title-4 ${styles.title}`}>
						Basic Info
					</h4>
					<Button className="btn-square _filled _conflower-blue">
						Edit Invoice Template
					</Button>
				</header>
				<div className="row mb-3">
					<div className="col-6">
						<TextField
							label="Contact Person"
							value={contactPerson}
							onChange={(v) => this.setState({contactPerson: v})}
						/>
					</div>
					<div className="col-6">
						<File
							withPreview
							label="Logo"
							btnTitle={<div className={styles.uploadBtn}>
								<i>
									<UploadIcon/>
								</i>
								Upload
							</div>}
							onChange={(e) => console.log(e.target.files)}
						/>
					</div>
				</div>
				<div className="row mb-3">
					<div className="col-6">
						<TextField
							label="Organization Name"
							value={organizationName}
							onChange={(v) => this.setState({organizationName: v})}
						/>
					</div>
					<div className="col-6">
						<TextField
							label="Contact Number"
							value={contactNumber}
							onChange={(v) => this.setState({contactNumber: v})}
						/>
					</div>
				</div>
				<div className="row mb-3">
					<div className="col-6">
						<TextField
							label="Contact Email"
							value={contactEmail}
							onChange={(v) => this.setState({contactEmail: v})}
						/>
					</div>
					<div className="col-6">
						<TextField
							label="App Url"
							value={appUrl}
							onChange={(v) => this.setState({appUrl: v})}
						/>
					</div>
				</div>
				<div className="row mb-3">
					<div className="col-6">
						<Select
							className={styles.select}
							label="Timezone"
							showControlLabel
							options={timeZones}
							value={timezone}
							onChange={(v) => this.setState({timezone: v})}
						/>
					</div>
					<div className="col-6">
						<Select
							className={styles.select}
							label="Language"
							showControlLabel
							options={languages}
							value={language}
							onChange={(v) => this.setState({language: v})}
						/>
					</div>
				</div>
				<div className="row align-items-end mb-5">
					<div className="col-6">
						<TextField
							label="Business Type"
							value={businessType}
							onChange={(v) => this.setState({businessType: v})}
						/>
					</div>
					<div className="col-6">
						<Select
							className={styles.select}
							label="AdvertisementBudget"
							showControlLabel
							options={advertisementBudgets}
							value={advertisementBudget}
							onChange={(v) => this.setState({advertisementBudget: v})}
						/>
					</div>
				</div>
				<h4 className="title-4 mb-4">
					Segment Settings
				</h4>
				<div className="row">
					{segmentSettings.map(i => (
						<div className="col-6 mb-2" key={i.label}>
							<Checkbox
								label={i.label}
								checked={i.checked}
								name={i.label}
								onChange={() => {
									i.checked = !i.checked;
									this.setState({segmentSettings});
								}}
							/>
						</div>
					))}
				</div>
			</div>
		);
	}
}

export default SettingsBasicInfo;