import React, {Component} from "react";
import {Switch, TextField, Select} from "factor";

import styles from "./Contact.module.scss";

import {testOptions} from "components/consts";

class Contact extends Component {
	state = {
		contactSwitch: true,
		addressLine1: "",
		addressLine2: "",
		addressLine3: "",
		city: "",
		state: {label: "California", value: "California"},
		zip: "",
		country: {label: "United States", value: "United States"},
	}

	render() {
		const {
			className,
		} = this.props;

		const {
			contactSwitch,
			addressLine1,
			addressLine2,
			addressLine3,
			city,
			state,
			zip,
			country,
		} = this.state;

		return (
			<div className={`block ${styles.container} ${className || ""}`}>
				<Switch
					className={styles.switch}
					name="customers-main-contact"
					list={["Contact", "Address"]}
					value={contactSwitch}
					onChange={(v) => this.setState({contactSwitch: v})}
				/>
				<div className="row justify-content-center">
					<div className="col-6">
						<div className={`${styles.item} mb-3`}>
							<TextField
								value={addressLine1}
								label="Address Line 1"
								onChange={(v) => this.setState({addressLine1: v})}
							/>
						</div>
						<div className={`${styles.item} mb-3`}>
							<TextField
								value={addressLine2}
								label="Address Line 2"
								onChange={(v) => this.setState({addressLine2: v})}
							/>
						</div>
						<div className={`${styles.item}`}>
							<TextField
								value={addressLine3}
								label="Address Line 3"
								onChange={(v) => this.setState({addressLine3: v})}
							/>
						</div>
					</div>
					<div className="col-6">
						<div className={`${styles.item} mb-3`}>
							<TextField
								value={city}
								label="City"
								onChange={(v) => this.setState({city: v})}
							/>
						</div>
						<div className={`${styles.item} mb-3`}>
							<div className="row">
								<div className="col-6">
									<Select
										label="State"
										showControlLabel
										value={state}
										options={[{label: "California", value: "California"}, ...testOptions]}
										onChange={(v) => this.setState({state: v})}
									/>
								</div>
								<div className="col-6 d-flex align-items-end">
									<TextField
										value={zip}
										label="Zip"
										onChange={(v) => this.setState({zip: v})}
									/>
								</div>
							</div>
						</div>
						<div className={`${styles.item}`}>
							<Select
								label="Country"
								showControlLabel
								value={country}
								options={[{label: "United States", value: "United States"}, ...testOptions]}
								onChange={(v) => this.setState({country: v})}
							/>
						</div>
					</div>
				</div>
			</div>
		)
	}
}

export default Contact;