import React, {Component} from "react";
import {Switch, Checkbox, Select} from "factor";

import styles from "./Settings.module.scss";

import {testOptions} from "components/consts";

const targetingOptionsTitles = ["Segments", "Location", "Scheduling", "Technology", "Bid Strategy", "Advanced"];
const appAccessTitles = ["Finance", "Apps", "Exchanges", "API Docs", "Segment", "Campaign Edit Budget"];

class Settings extends Component {
	state = {
		settingsSwitch: true,
		targetingOptions: targetingOptionsTitles.map(i => true),
		appAccess: appAccessTitles.map(i => true),
	}

	render() {
		const {
			className,
		} = this.props;

		const {
			settingsSwitch,
			targetingOptions,
			appAccess,
		} = this.state;

		return (
			<div className={`block ${styles.container} ${className || ""}`}>
				<Switch
					className={styles.switch}
					name="customers-main-settings"
					list={["Settings", "Access"]}
					value={settingsSwitch}
					onChange={(v) => this.setState({settingsSwitch: v})}
				/>
				<div className="row justify-content-center">
					<div className="col-4">
						<h4 className="title-4 mb-3">
							Targeting Options
						</h4>
						{targetingOptionsTitles.map((i, k) => (
							<Checkbox
								key={i}
								className={styles.checkbox}
								label={i}
								checked={targetingOptions[k]}
								name={`customers-main-settings Targeting Options ${i}`}
								onChange={() => {
									targetingOptions[k] = !targetingOptions[k];
									this.setState({targetingOptions});
								}}
							/>
						))}
					</div>
					<div className="col-4">
						<h4 className="title-4 mb-3">
							App Access
						</h4>
						{targetingOptionsTitles.map((i, k) => (
							<Checkbox
								key={i}
								className={styles.checkbox}
								label={i}
								checked={appAccess[k]}
								name={`customers-main-settings App Access ${i}`}
								onChange={() => {
									appAccess[k] = !appAccess[k];
									this.setState({appAccess});
								}}
							/>
						))}
					</div>
				</div>
			</div>
		)
	}
}

export default Settings;