import React from "react";
import {Checkbox} from "factor";

import styles from "./CheckboxList.module.scss";

const CheckboxList = props => {
	const {
		data,
		onChange,
		onAllChange,
		disabled,
	} = props;

	const allSelected = data.list.length === data.list.filter(i => i.checked).length;

	return (
		<div className={`${styles.container} ${disabled ? "_disabled" : ""}`}>
			<h4 className={`title-4 mb-3 ${styles.title}`}>
				{data.title}
			</h4>
			<Checkbox
				disabled={disabled}
				className={styles.all}
				label="All"
				checked={allSelected}
				name={`${data.title}`}
				onChange={() => onAllChange(allSelected)}
			/>
			<ul className={styles.list}>
				{data.list.map((i, k) => (
					<li className={styles.item} key={i.label}>
						<Checkbox
							disabled={disabled}
							className={styles.checkbox}
							label={i.label}
							checked={i.checked}
							name={`${data.title}-${i.label}`}
							onChange={() => onChange(k)}
						/>
					</li>
				))}
			</ul>
		</div>
	)
};

export default CheckboxList;