import React, {Component} from "react";
import {TextField, Select, Checkbox} from "factor";

import styles from "./DefaultCustomerSettings.module.scss";

import CheckboxList from "components/shared/CheckboxList/CheckboxList";

import {testOptions} from "components/consts";

const exchanges = testOptions;
const advertiserTypes = testOptions;
const paymentTerms = [ {label: "Net 60", value: "Net 60" }, ...testOptions ];

const optionsData = [
	{
		title: "Targeting Options",
		list: ["Segment", "Location", "Scheduling", "Technology", "Bid Strategy", "Advanced"],
	},
	{
		title: "App Access",
		list: ["Finance", "Exchanges", "Segment", "Report Builder", "Campaign Edit Budget"],
	},
];

class DefaultCustomerSettings extends Component {
	state = {
		exchange: exchanges[0],
		advertiserType: advertiserTypes[0],
		paymentTerm: paymentTerms[0],
		campaignMinBudget: "",
		advertiserMargin: "",
		emailNotifications: true,
		checkboxes: optionsData.map(i => {
			return {
				title: i.title,
				list: i.list.map(ii => ({label: ii, checked: true})),
			}
		}),
	}

	render() {
		const {
			exchange,
			advertiserType,
			paymentTerm,
			campaignMinBudget,
			advertiserMargin,
			emailNotifications,
			checkboxes,
		} = this.state;
		return (
			<div className="block">
				<div className="row">
					<div className="col-6">
						<header className={styles.header}>
							<h4 className={`title-4 ${styles.title}`}>
								Basic Info
							</h4>
						</header>
						<div className="row mb-3">
							<div className="col-6">
								<Select
									className={styles.select}
									label="Exchanges"
									showControlLabel
									options={exchanges}
									value={exchange}
									onChange={(v) => this.setState({exchange: v})}
									isMulti
									allSelectable
								/>
							</div>
							<div className="col-6">
								<Select
									className={styles.select}
									label="Advertiser Type"
									showControlLabel
									options={advertiserTypes}
									value={advertiserType}
									onChange={(v) => this.setState({advertiserType: v})}
									isMulti
									allSelectable
								/>
							</div>
						</div>
						<div className="row mb-3">
							<div className="col-6">
								<TextField
									label="Campaign Min Budget"
									value={campaignMinBudget}
									onChange={(v) => this.setState({campaignMinBudget: v})}
								/>
							</div>
							<div className="col-6">
								<TextField
									label="Advertiser Margin"
									value={advertiserMargin}
									onChange={(v) => this.setState({advertiserMargin: v})}
								/>
							</div>
						</div>
						<div className="row align-items-end mb-3">
							<div className="col-6">
								<Select
									className={styles.select}
									label="Payment Term"
									showControlLabel
									options={paymentTerms}
									value={paymentTerm}
									onChange={(v) => this.setState({paymentTerm: v})}
								/>
							</div>
							<div className="col-6">
								<Checkbox
									label="Email Notifications"
									checked={emailNotifications}
									name="Email Notifications"
									onChange={() => this.setState({emailNotifications: !emailNotifications})}
								/>
							</div>
						</div>
					</div>
					{checkboxes.map((i, k) => (
						<div className="col-3" key={k}>
							<CheckboxList
								data={i}
								onChange={(k) => {
									i.list[k].checked = !i.list[k].checked;
									this.setState({checkboxes});
								}}
								onAllChange={(allSelected) => {
									i.list.forEach(ii => ii.checked = !allSelected);
									this.setState({checkboxes});
								}}
							/>
						</div>
					))}
				</div>
			</div>
		);
	}
}

export default DefaultCustomerSettings;