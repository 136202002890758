import React, {Component} from "react";
import {Switch, Select, TextFieldWithIcon, Button} from "factor";
import {connect} from "react-redux";

import styles from "./OrganizationSidebar.module.scss";

import Sidebar from "components/SideBar/Sidebar";
import List from "components/shared/List/List";
import Invite from "./Invite/Invite";

import {switchOrganization, setRole} from "store/actions";

import {devices} from "components/consts";

const membersOptions = [
	{label: "All", value: "All"},
	{label: "not All", value: "not All"},
];

const membersList = [
	{key: "Ivan Guerrero", value: "08/30/2019"},
	{key: "Bernice Arnold", value: "11/06/2019"},
	{key: "Alice Leonard", value: "09/04/2019"},
	{key: "Rosalie Porter", value: "10/07/2019"},
	{key: "Eva Wilkins", value: "06/08/2019"},
	{key: "Anne Webb", value: "02/20/2019"},
	{key: "Rena Brock", value: "07/26/2019"},
];

const rolesList = [
	{key: "Account Owner", value: "Tim Wilson"},
	{key: "Admin", value: "3"},
	{key: "Advertiser", value: "25"},
	{key: "Sr Advertiser", value: "5"},
	{key: "Creator", value: "4"},
	{key: "Data Scientist", value: "2"},
	{key: "Strategist", value: "1"},
];

class OrganizationSidebar extends Component {
	state = {
		membersSelect: membersOptions[0],
		search: "",
		showInviteForm: false,
		member: undefined,
	}

	render() {
		const {
			organizationSwitch,
			switchOrganization,
			activeRole,
			setRole,
		} = this.props;

		const {
			membersSelect,
			search,
			showInviteForm,
			member,
		} = this.state;

		return (
			<Sidebar
				header={<div className={styles.header}>
					<Switch
						className={styles.btnGroup}
						name="organizationSwitch"
						list={devices}
						value={organizationSwitch === "Roles"}
						onChange={(v) => switchOrganization(v ? "Roles" : "Members")}
					/>
					<div className="row">
						<div className="col-6">
							<Select
								value={membersSelect}
								options={membersOptions}
								onChange={(v) => this.setState({creativeFramework: v})}
							/>
						</div>
						<div className="col-6">
							<TextFieldWithIcon
								placeholder="Search"
								iconName="Search"
								value={search}
								onChange={({target}) => this.setState({search: target.value})}
							/>
						</div>
					</div>
				</div>}
				lastChildren={
					<Invite
						className={showInviteForm ? "" : "_hidden"}
						close={() => this.setState({showInviteForm: false})}
					/>
				}
				containerHidden={showInviteForm}
			>
				{organizationSwitch === "Roles" ?
					<List
						className={styles.list}
						list={rolesList}
						header={["Role", "Assignees"]}
						active={activeRole}
						onChange={(i) => setRole(i)}
					/>
					:
					<List
						className={styles.list}
						list={membersList}
						active={member}
						onChange={(i) => this.setState({member: i})}
					/>
				}

				{!showInviteForm &&
					<div className={styles.buttons}>
						<Button
							className="btn-round _filled _conflower-blue"
							onClick={() => this.setState({showInviteForm: true})}
						>
							Invite Members
						</Button>
					</div>
				}
			</Sidebar>
		)
	}
}

export default connect(
	state => ({
		organizationSwitch: state.organizationSwitch,
		activeRole: state.activeRole,
	}),
	dispatch => ({
		...switchOrganization(dispatch),
		...setRole(dispatch),
	}),
)(OrganizationSidebar);