import React, {Component} from "react";
import {TextField, Select, Button} from "factor";

import styles from "./Invite.module.scss";

const roles = [
	{label: "Admin", value: "Admin"},
	{label: "Manager", value: "Manager"},
];

class Invite extends Component {
	state = {
		firstName: "",
		lastName: "",
		emailAddress: "",
		role: roles[0],
	}

	render() {
		const {
			className,
			close,
		} = this.props;

		const {
			firstName,
			lastName,
			emailAddress,
			role,
		} = this.state;

		return (
			<div className={`${styles.container} ${className || ""}`}>
				<h4 className={`title-4 ${styles.title}`}>
					Invite Member
				</h4>
				<div className="row mb-3">
					<div className="col-6">
						<TextField
							label="First Name"
							value={firstName}
							onChange={(v) => this.setState({firstName: v})}
						/>
					</div>
					<div className="col-6">
						<TextField
							label="Last Name"
							value={lastName}
							onChange={(v) => this.setState({lastName: v})}
						/>
					</div>
				</div>
				<div className="mb-3">
					<TextField
						label="Email Address"
						value={emailAddress}
						onChange={(v) => this.setState({emailAddress: v})}
					/>
				</div>
				<Select
					className={`${styles.select} mb-3`}
					value={role}
					options={roles}
					onChange={(v) => this.setState({role: v})}
				/>
				<div className={styles.buttons}>
					<Button
						className="btn-square _conflower-blue mr-3"
						onClick={close}
					>
						Cancel
					</Button>
					<Button
						className="btn-square _filled _conflower-blue"
						onClick={close}
					>
						Invite
					</Button>
				</div>
			</div>
		)
	}
}

export default Invite;