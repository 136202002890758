import React, {Component} from "react";
import {TextField, Button, Checkbox} from "factor";

import styles from "./Invite.module.scss";

import Collapse from "components/shared/Collapse/Collapse";

const checkboxesTitles = ["Dashboard", "Campaign Builder", "Report Builder", "Audience Builder", "Voter Intelligence", "Creative Builder", "Bid Management", "Inventory Manager"];

class Invite extends Component {
	state = {
		organizationName: "",
		contactEmailAddress: "",
		checkboxes: checkboxesTitles.map(i => false),
	}

	render() {
		const {
			className,
			close,
		} = this.props;

		const {
			organizationName,
			contactEmailAddress,
			checkboxes,
		} = this.state;

		return (
			<div className={`${styles.container} ${className || ""}`}>
				<h4 className={`title-4 ${styles.title}`}>
					Invite Customers
				</h4>
				<div className="mb-3">
					<TextField
						label="Organization Name"
						value={organizationName}
						onChange={(v) => this.setState({organizationName: v})}
					/>
				</div>
				<div className="mb-3">
					<TextField
						label="Contact Email Address"
						value={contactEmailAddress}
						onChange={(v) => this.setState({contactEmailAddress: v})}
					/>
				</div>
				<Collapse
					className={styles.collapse}
					title={`Apps ${checkboxes.filter(i => i).length}/${checkboxes.length}`}
					isOpened={true}
				>
					<div className={styles.checkboxes}>
						{checkboxesTitles.map((i, k) => (
							<Checkbox
								key={i}
								className={styles.checkbox}
								label={i}
								checked={checkboxes[k]}
								name={`customers-sidebar-invite Apps ${i}`}
								onChange={() => {
									checkboxes[k] = !checkboxes[k];
									this.setState({checkboxes});
								}}
							/>
						))}
					</div>
				</Collapse>
				<div className={styles.buttons}>
					<Button
						className="btn-round _conflower-blue mr-3"
						onClick={close}
					>
						Cancel
					</Button>
					<Button
						className="btn-round _filled _conflower-blue"
						onClick={close}
					>
						Invite
					</Button>
				</div>
			</div>
		)
	}
}

export default Invite;