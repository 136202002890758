import React from "react";
import {connect} from "react-redux";
import {Layout} from "factor";

import styles from "./Customers.module.scss";

import Main from "components/Main/Main";
import CustomersSidebar from "./CustomersSidebar/CustomersSidebar";
import CustomersMain from "./CustomersMain/CustomersMain";
import RightSidebar from "components/RightSidebar/RightSidebar";
import Preview from "components/Preview/Preview";

import {showCreativePreview, toggleSidebar} from "store/actions";

const Customers = props => {
  const {
    showCreativePreview,
    creativePreviewOpened,
    activeSidebar,
    toggleSidebar,
  } = props;

  return (
    <React.Fragment>
      <Main>
        <Layout
          opened={activeSidebar}
          toggleSidebar={(opened) => toggleSidebar(typeof opened === 'boolean' ? opened : !activeSidebar)}
          sidebar={<CustomersSidebar/>}
        >
          <CustomersMain/>
          <RightSidebar/>
        </Layout>
      </Main>

      <Preview open={creativePreviewOpened} onClickOutside={() => showCreativePreview(false)}/>
    </React.Fragment>
  )
};

export default connect(
  state => ({
    creativePreviewOpened: state.creativePreviewOpened,
    activeSidebar: state.activeSidebar,
  }),
  dispatch => ({
    ...showCreativePreview(dispatch),
    ...toggleSidebar(dispatch),
  })
)(Customers);
