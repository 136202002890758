import React, {Component} from "react";

import styles from "./Sidebar.module.scss";

class Sidebar extends Component {

	render() {
		const {
			className,
			contentClassName,
			notFixed,
			children,
			header,
			footer,
			lastChildren,
			containerHidden,
		} = this.props;

		let containerClassNames = [styles.sidebar];
		if(notFixed) {
			containerClassNames.push("_not-fixed");
		}

		if(className) {
			containerClassNames.push(className);
		}

		return (
			<div className={containerClassNames.join(" ")}>
				<div className={`${styles.container} ${containerHidden ? "_hidden" : ""}`}>
					{header &&
						<div className={styles.header}>
							{header}
						</div>
					}
					<div className={styles.main}>
						<div className={`${styles.content} ${contentClassName || ""}`}>
							{children}
						</div>
					</div>
					{footer &&
						<footer className={styles.footer}>
							{footer}
						</footer>
					}
				</div>
				{lastChildren}
			</div>
		);
	}
}

export default Sidebar;
