import React, {Component} from "react";
import {Select, Button} from "factor";

import styles from "./Creatives.module.scss";

import Sidebar from "components/SideBar/Sidebar";
import CreativesList from "components/CreativesList/CreativesList";

import {rightSidebarHeaderOptions} from "components/consts";

class Creatives extends Component {
	state = {
		headerSelect: rightSidebarHeaderOptions[0],
	}

	header = () => {
		const {
			close,
		} = this.props;

		const {
			headerSelect,
		} = this.state;

		return (
			<div className={styles.header}>
				<div className={styles.headerRow}>
					<button
						className={`btn-close ${styles.close}`}
						onClick={close}
					></button>
					<h4 className={`title-4 ${styles.title}`}>
						Approve Creatives
					</h4>
					<Select
						className={styles.headerSelect}
						options={rightSidebarHeaderOptions}
						value={headerSelect}
						onChange={(v) => this.setState({headerSelect: v})}
					/>
				</div>
				<div className={`${styles.headerRow} ${styles.pending}`}>
					<div className={styles.pendingTitle}>
						14 Creatives Pending
					</div>
					<Button className={`btn-round _filled _conflower-blue ${styles.pendingApprove}`}>
						Approve All
					</Button>
				</div>
			</div>
		)
	}

	render() {

		return (
			<Sidebar
				className="_right-sidebar"
				notFixed
				header={this.header()}
			>
				<CreativesList
					view="tile"
					column={true}
				/>
			</Sidebar>
		)
	}
}



export default Creatives;