import React, {Component} from "react";

import styles from "./Options.module.scss";

import CheckboxList from "components/shared/CheckboxList/CheckboxList";

const optionsData = [
	{
		title: "Manage",
		list: ["Advertisers", "Campaigns", "Creatives", "Header Bidding", "Custom Audience"],
	},
	{
		title: "Finance Access",
		list: ["Overview", "Manage Payments", "Manage Refunds", "Manage Credit", "Manage Invoice"],
	},
	{
		title: "Hide",
		list: ["View User Profiles", "Customize Profile", "View Performance", "Finance Data", "Earnings"],
	},
	{
		title: "App Access",
		list: ["Finance", "Exchanges", "Segment", "Report Builder", "Campaign Edit Budget"],
	},
];

class Options extends Component {
	state = {
		checkboxes: optionsData.map(i => {
			return {
				title: i.title,
				list: i.list.map(ii => ({label: ii, checked: true})),
			}
		}),
	}

	render() {
		const {
			className,
			activeRole,
		} = this.props;

		const {
			checkboxes,
		} = this.state;

		const isAccountOwner = activeRole && (activeRole.key === "Account Owner");

		return (
			<div className={`block ${styles.container} ${className || ""} ${isAccountOwner ? "_disabled" : ""}`}>
				<div className="row">
					{checkboxes.map((i, k) => (
						<div className="col-3" key={k}>
							<CheckboxList
								disabled={isAccountOwner}
								data={i}
								onChange={(k) => {
									i.list[k].checked = !i.list[k].checked;
									this.setState({checkboxes});
								}}
								onAllChange={(allSelected) => {
									i.list.forEach(ii => ii.checked = !allSelected);
									this.setState({checkboxes});
								}}
							/>
						</div>
					))}
				</div>
			</div>
		)
	}
}

export default Options;