import React from "react";
import {connect} from "react-redux";

import styles from "./CustomersMain.module.scss";

import MainContent from "components/MainContent/MainContent";
import Header from "./Header/Header";
import Summary from "./Summary/Summary";
import Chart from "./Chart/Chart";
import Events from "./Events/Events";
import Contact from "./Contact/Contact";
import Settings from "./Settings/Settings";

const CustomersMain = props => {
	const {
		selectedCustomersLength,
	} = props;

	const isMultipleCustomers = selectedCustomersLength > 1;

	return (
		<MainContent>
			<div className={styles.container}>
				<Header className="mb-3" selectedCustomersLength={selectedCustomersLength}/>
				<div className="row align-items-stretch mb-3">
					{!isMultipleCustomers &&
						<div className="col-3 d-flex align-items-stretch">
							<Summary/>
						</div>
					}
					<div className={isMultipleCustomers ? "col" : "col-9"}>
						<Chart/>
					</div>
				</div>
				{!isMultipleCustomers ?
					<div className="row">
						<div className="col-5">
							<Events/>
						</div>
						<div className="col-7">
							<Contact className="mb-3"/>
							<Settings/>
						</div>
					</div>
					:
					<div className="row">
						<div className="col-6">
							<Events isMultipleCustomers={isMultipleCustomers} title="Customer"/>
						</div>
						<div className="col-6">
							<Events isMultipleCustomers={isMultipleCustomers} title="Member"/>
						</div>
					</div>
				}
			</div>
		</MainContent>
	);
};

export default connect(
	state => ({selectedCustomersLength: state.selectedCustomersLength}),
)(CustomersMain);